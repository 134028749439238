import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        selectedNavItem: null,
        textColor: "#062a4e",
        constant: {},
        isLoggedIn: false,
    },
    mutations: {
        setLoggedIn(state, status) {
            state.isLoggedIn = status;
        },
    }
    })