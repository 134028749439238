var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"margin-top":"50px","margin-bottom":"70px"}},[_c('v-row',{staticClass:"mt-10 d-flex justify-center"},[_c('v-col',{staticStyle:{"max-width":"500px"},attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-4",staticStyle:{"box-shadow":"0 0 10px #fff"}},[_c('v-img',{staticClass:"my-3 mx-auto",style:({ 'width': _vm.isResponsive ? '60%' : '100%' }),attrs:{"src":require('../../assets/default_logo.png'),"contain":"","height":"200"}}),_c('div',[_c('span',{staticClass:"d-flex justify-center"},[_c('h1',{staticStyle:{"color":"#1e517b"}},[_vm._v("הרשמה")])])]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler.apply(null, arguments)}}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"שם","type":"text","rules":[v => !!v || 'שדה חובה'],"placeholder":"שם","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"אימייל","type":"text","rules":[
              v => !!v || 'שדה חובה',
              v => /.+@.+\..+/.test(v) || 'יש להזין כתובת אימייל תקינה'],"placeholder":"אימייל","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":"סיסמה","type":"password","rules":[
              v => !!v || 'שדה חובה',
              v => v && v.length >= 9 || 'הסיסמה צריכה להכיל לפחות 9 תווים',
              v => /[a-zA-Z]/.test(v) && /\d/.test(v) || 'הסיסמה צריכה לכלול אותיות באנגלית ומספרים'
            ],"placeholder":"סיסמה","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',[_c('v-checkbox',{staticStyle:{"margin-bottom":"0px !important","margin-top":"0px !important"},attrs:{"rules":[v => !!v || 'חובה לאשר את התנאים'],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" אני מאשר את "),_c('span',{staticStyle:{"color":"blue","cursor":"pointer","text-decoration":"underline"},on:{"click":_vm.navigateToTerms}},[_vm._v(" תנאי השימוש ")])])]},proxy:true}]),model:{value:(_vm.confirmTerms),callback:function ($$v) {_vm.confirmTerms=$$v},expression:"confirmTerms"}})],1),_c('span',{on:{"click":function($event){return _vm.openLogin()}}},[_vm._v(" יש לך כבר חשבון? "),_c('a',{staticStyle:{"color":"rgb(10, 10, 197)"}},[_vm._v("היכנס")])])],1),_c('v-card-actions',{staticClass:"justify-center",staticStyle:{"margin-top":"20px","margin-bottom":"10px"}},[_c('v-btn',{attrs:{"min-width":"140"},on:{"click":function($event){return _vm.register()}}},[_c('span',[_vm._v("הירשם")])])],1),(_vm.progressShow)?_c('v-row',{staticClass:"my-5"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()],1)],1)],1)],1),_c('SnackBar',{attrs:{"snacbarText":_vm.snacbarText,"snackbarColorBt":_vm.snackbarColorBt},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }