import { render, staticRenderFns } from "./AdminView.vue?vue&type=template&id=5c0f2e80&scoped=true&"
import script from "./AdminView.vue?vue&type=script&lang=js&"
export * from "./AdminView.vue?vue&type=script&lang=js&"
import style0 from "./AdminView.vue?vue&type=style&index=0&id=5c0f2e80&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0f2e80",
  null
  
)

export default component.exports