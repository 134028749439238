<template>
  <v-app style="background-color: rgb(238, 242, 246);">
    <v-main>
      <RestDetails @setRestName="handleRestNameChange" />
    </v-main>
  </v-app>
</template>
<script>
import RestDetails from '@/components/rest-details-page/RestDetails'

export default {
  components: {
    RestDetails
  },
  data() {
    return {
      RestName: "פרטי המסעדה" // Default restaurant name
    }
  },
  methods: {
    handleRestNameChange(newName) {
      this.RestName = newName;
      this.updatePageTitle();
    }
  },
  mounted() {
    this.updateDocumentTitle();
    this.updatePageTitle();
  },
  updatePageTitle() {
    if (this.$vuetify.rtl) {
      return document.title =  `כשר בסביבה | ${this.RestName}`;
    } else {
      return document.title = `Kosher Basviva | ${this.RestName}`;
    }
  },
}
</script>
   