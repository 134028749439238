
class CheckOpen {
    constructor() {
    }

    static checkOpen(openHours) {

        const currentDate = new Date();
        const currentDayName = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][currentDate.getDay()];
        const currentHour = currentDate.getHours();
        const currentMinute = currentDate.getMinutes();
        // Check if the restaurant is open or closed
        if (!openHours[currentDayName].open) {
            return "isEmpty"
        }
        const openingTime = openHours[currentDayName].open.split(":");
        const closingTime = openHours[currentDayName].close.split(":");
        const openHour = parseInt(openingTime[0]);
        const openMinute = parseInt(openingTime[1]);
        const closeHour = parseInt(closingTime[0]);
        const closeMinute = parseInt(closingTime[1]);

        if (
            (currentHour > openHour || (currentHour === openHour && currentMinute >= openMinute)) &&
            (currentHour < closeHour || (currentHour === closeHour && currentMinute <= closeMinute))
        ) {
            return "true"
        } else {
            return "false"
        }

    }

}

export default CheckOpen;

