<template>
    <v-dialog v-model="openMode" max-width="500px">

        <v-card class="scroll-container">

            <v-card-title class="text-h5 d-flex justify-space-between">
                <div>
                </div>
                <div>
                    שיתוף
                </div>
                <div>
                    <button type="button" class="btn-close" @click="close()"></button>
                </div>
            </v-card-title>

            <v-card-text class="text--primary">
                <div>שתף קישור באמצעות: </div>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">

                <v-btn icon class="border border-2" color="green darken-2" x-large @click="shareWemail(itemObj)">
                    <v-icon>mdi-email</v-icon>
                </v-btn>

                <v-btn icon class="border border-2" x-large @click="shareWtelegram(itemObj)">
                    <v-img src="@/assets/telegram.svg" />
                </v-btn>
                <v-btn icon class="border border-2" color="blue darken-2" x-large @click="shareWsms(itemObj)">
                    <v-icon>mdi-message-processing</v-icon>
                </v-btn>

                <v-btn icon class="border border-2" color="green" x-large :href="'https://wa.me/?text=' + forwardMessage"
                    target="_blank">
                    <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
            </v-card-actions>

             <!-- Link Copy Section -->
             <div class="d-flex flex-column align-center my-4">
                <div class="text--primary gray--text">או העתק קישור</div>
                <v-text-field
                    outlined
                    readonly
                    :value="getCurrentURL"
                    append-icon="mdi-content-copy"
                    @click:append="copied"
                    class="mt-2"
                ></v-text-field>
            </div>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import SnackBar from '@/components/widgets/snackBar'

export default {
    props: { value: { type: Boolean, default: false }, itemObj: Object },
    components: {
        SnackBar
    },
    data() {
        return {
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
        }
    },
    methods: {
        close() {
            this.openMode = false;
        },
        copied() {
            navigator.clipboard.writeText(this.getCurrentURL);
            this.showSnackBar("הקישור הועתק!", "green")
        },
        shareWemail() {

            window.open(`mailto:?subject=&body=${this.forwardMessage}`);

        },
        shareWtelegram() {

            window.open(`tg://msg_url?url=&text=${this.forwardMessage}`);

        },
        shareWsms() {

            window.open(`sms://?body=${this.forwardMessage}`, '_self')

        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        getCurrentURL() {
            return window.location.href;
        },
        forwardMessage() {
            return "אתר המסעדות 'כשר בסביבה'- מסעדות בהכשר הבד\"ץ" + "%0a" + "קישור לעסק:" + "%0a" + this.itemObj.name + "%0a" + "עיר: " + this.itemObj.city + "%0a" + "כנסו ל" + "%0a" + this.getCurrentURL + "%0a"
        },
    },
    mounted() {
    },

}
</script>
<style scoped>
.scroll-container {
    overflow-y: auto !important;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* For Webkit browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
    width: 0 !important;
    background-color: transparent !important;
}
</style>
  