<template>
    <v-row justify="center">
        <v-dialog v-model="openMode" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="openMode = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>סינון</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items style="margin-inline-end: 10px;">
                        <v-btn dark text @click="ressetChoosed()">
                            איפוס
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items>
                        <v-btn dark text @click="searchFiltered()">
                            סנן וחפש
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-expansion-panels accordion focusable multiple style="max-width: 100%; ">
                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header>כשרות</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!constantsChoosed.kashruyot.length > 0 || constantsChoosed.kashruyot.every(item => item === false || item === 'empty')">
                                <span style="font-weight: bold;">מציג הכל</span>
                            </div>
                            <v-checkbox v-for="(kashrut, index) in constants.kashruyot"
                                v-model="constantsChoosed.kashruyot[index]" :label="kashrut" :value="kashrut"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header>סוג</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!constantsChoosed.types.length > 0 || constantsChoosed.types.every(item => item === false || item === 'empty')">
                                <span style="font-weight: bold;">מציג הכל</span>
                            </div>
                            <v-checkbox v-for="(type, index) in constants.types" v-model="constantsChoosed.types[index]"
                                :value="type" :label="type"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header>קטגוריה</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!constantsChoosed.categories.length > 0 || constantsChoosed.categories.every(item => item === false || item === 'empty')">
                                <span style="font-weight: bold;">מציג הכל</span>
                            </div>
                            <v-checkbox v-for="(category, index) in constants.categories"
                                v-model="constantsChoosed.categories[index]" :value="category" :label="category"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header>תת קטגוריה</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!constantsChoosed.sub_categories.length > 0 || constantsChoosed.sub_categories.every(item => item === false || item === 'empty')">
                                <span style="font-weight: bold;">מציג הכל</span>
                            </div>
                            <v-checkbox v-for="(sub_category, index) in constants.sub_categories"
                                v-model="constantsChoosed.sub_categories[index]" :value="sub_category" :label="sub_category"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>


            </v-card>
        </v-dialog>

    </v-row>
</template>
<script>
export default {
    props: {
        constantsChoosed: Object,
        constants: Object,
        value: { type: Boolean, default: false },

    },
    data: () => ({
    }),

    methods: {

        searchFiltered() {
            this.$emit('searchFiltered')
            this.openMode = false;
        },
        ressetChoosed() {
            this.$emit('ressetChoosed')
        }

    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
<style></style>
    