<template>
    <v-app style="background-color: #f3f6f9">
        <v-main>           
            <ChartsStatisticComp />
        </v-main>
    </v-app>
</template>
<script>
import Auth from '@/services/auth.service.js'
import ChartsStatisticComp from '@/components/admin/ChartsStatisticComp.vue'

export default {
    components: {
        ChartsStatisticComp,
    },
    data() {
        return {
        };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        document.title = 'סטטיסטיקות | כשר בסביבה'
        let auth = Auth.checkAuth();
        if (!auth) {
            return this.$router.replace({ name: "login" })
        }
    },
}
</script>
<style>
/* .container1 {
        width: 1000px; 
        margin: 0 auto; */
/* display: flex; */
/* flex-direction: column; */
/* justify-content: center; */
/* align-items: center; */
/* height: 100vh;  */
/* } */
</style>