// i18n.js
import VueI18n from 'vue-i18n';
import Auth from './services/auth.service.js'; // adjust the import path as needed
import Vue from 'vue';

Vue.use(VueI18n)

import homeEn from './locales/en/home.json';
import homeHe from './locales/he/home.json';
import navEn from './locales/en/nav.json';
import navHe from './locales/he/nav.json';

export const messages = {
  en: {
    home: homeEn,
    nav: navEn,

  },
  he: {
    home: homeHe,
    nav: navHe,
  },
};

// // Create an instance of i18n
const i18n = new VueI18n({
  locale: Auth.getUserLanguage() || 'he', // Default language
  fallbackLocale: 'he', // Fallback language
  messages: messages // Initially empty, to be loaded dynamically
});

export default i18n;