import ApiServices from '@/services/api.service'

class Favorites {
    constructor() {
    }

    static async addOrRemoveToFavorite(item) {

      
        try {
        const token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + "/user/add_to_favorites";
        const res = await fetch(api, ApiServices.requestOptions("POST", item, token));
        const jsonObject = await res.json();
        if (res.status === 400) {
            return false;
        } else if (res.status === 200) {
          console.log(jsonObject);
            return jsonObject.message
        }
      } catch (error) {
        return false;
      }
    }

    static async getUserFavorites() {

      try {
      const token = localStorage.getItem("token");
      let api = process.env.VUE_APP_BASE_URL + "/user/get_user_favorites";
      const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
      const jsonObject = await res.json();
      if (res.status === 400) {
          return [];
      } else if (res.status === 200) {
          return jsonObject.favorites.favorites
      }
    } catch (error) {
      return false;
    }
  }

    

}

export default Favorites;

