<template>
    <v-app style="background-color: rgb(238, 242, 246);">
        <v-main>
            <div class="center-container">
                <v-btn style="margin-inline-end: 70px;" @click="choosedAction(1)">הוסף מסעדה</v-btn>
                <v-btn @click="choosedAction(2)">ערוך מסעדה</v-btn>
                <v-btn @click="choosedAction(3)">ניקוי זיכרון זמני</v-btn>
                <div>
                    <select v-model="selectedLocale" @change="changeLanguage">
                        <option value="en">English</option>
                        <option value="he">French</option>
                    </select>
                </div>
                <span>{{ message }}</span>
            </div>

            <EditRestaurant v-if="editRestaurant"></EditRestaurant>
            <AddRestaurant v-if="addRestaurant"></AddRestaurant>
        </v-main>
    </v-app>
</template>
<script>
import AddRestaurant from '@/components/admin/AddRestaurant'
import EditRestaurant from '@/components/admin/EditRestaurant'
import Auth from '@/services/auth.service.js'
import ApiServices from '@/services/api.service'

export default {
    components: {
        AddRestaurant,
        EditRestaurant,
    },
    data() {
        return {
            addRestaurant: false,
            editRestaurant: false,
            message: "",
        }
    },
    methods: {
        choosedAction(action) {
            if (action == 1) {
                this.addRestaurant = true;
                this.editRestaurant = false;
            }
            else if (action == 2) {
                this.editRestaurant = true;
                this.addRestaurant = false;
            } else if (action == 3) {
                this.cleanCache();
            } else if (action == 4) {
                this.changeLang();
            }
        },
        async cleanCache() {
            try {
                let api = process.env.VUE_APP_BASE_URL + "/admin/clean_cache";
                const token = localStorage.getItem("token");
                const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.message = "שגיאה!";
                } else if (res.status === 200) {
                    this.message = "בוצע בהצלחה";
                }
            } catch (error) {
                this.message = error.message;
            }
        },
    },
    mounted() {
        document.title = 'כשר בסביבה | ניהול '
        let auth = Auth.checkAuth();
        if (!auth) {
            this.$router.replace({ name: "home" })
        }
    },

}
</script>
<style scoped>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-right: 5px;
    margin-left: 5px;
}
</style>