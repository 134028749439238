<template >
  <div style="margin-top: 100px;">
    <div style="height: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
      <span style="font-size: large; color: #0d2c6d;">סטטיסטיקות</span>
    </div>

    <div style="width: 100%; margin-top: 30px;">
      <v-row style=" border-radius: 20px 20px 0 0; padding: 20px; display: flex; justify-content: center;">
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: large;">סה"כ משתמשים אפליקציה </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 4px;">{{ appUsersEntrance.length }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: large;">סה"כ משתמשים אתר </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 4px;">{{ webUsersEntrance.length }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: large;">סה"כ פניות אפליקציה </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 4px;">{{ appRequest.length }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: large;">סה"כ פניות אתר </span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 4px;">{{ webRequest.length }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: large;">סה"כ משתמשים היום</span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 4px;">{{ allUsersEntranceFromToday }}</span>
        </v-col>
        <v-col cols="12" md="2" class="my-custom-col">
          <span style="font-size: large;">סה"כ פניות היום</span>
          <span style="font-weight: bold; font-size: x-large; margin-right: 4px;">{{ allRequestFromToday }}</span>
        </v-col>
      </v-row>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
        </v-col>
      </v-row>
      <v-row style=" border-radius: 20px 20px 0 0; display: flex; padding-bottom: 40px; justify-content: center; ">
        <v-col cols="12"
          style="background-color: white; height: 670px !important; display: flex; justify-content: center;">
          <canvas id="myChart"></canvas>
        </v-col>
      </v-row>
      <v-row style=" border-radius: 20px 20px 0 0; display: flex; padding-bottom: 40px; justify-content: center; ">
        <v-col cols="12"
          style="background-color: white; height: 670px !important; display: flex; justify-content: center;">
          <canvas id="myChart2"></canvas>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import Chart from 'chart.js/auto';
import ApiServices from '@/services/api.service'

export default {
  components: {
    SnackBar,
  },
  data: () => ({
    webRequest: [],
    appRequest: [],
    webUsersEntrance: [],
    appUsersEntrance: [],
    allRequestFromToday: 0,
    allUsersEntranceFromToday: 0,
    progressShow: false,
    chartInstance: null, // Keep track of the chart instance
    chartInstance2: null, // Keep track of the chart instance
  }),
  computed: {
  },
  methods: {
    async getDetails() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/admin/get_statistics`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.webRequest = jsonObject.statisticsRequestWeb;
          this.appRequest = jsonObject.statisticsRequestApp;
          this.webUsersEntrance = jsonObject.uniqueVisitorstWeb;
          this.appUsersEntrance = jsonObject.uniqueVisitorsApp;


          const webUsersEntranceCounts = jsonObject.webUniqueVisitorsLast15Days;
          const appUsersEntranceCounts = jsonObject.appUniqueVisitorsLast15Days;
          const webRequestCounts = this.countByDate(jsonObject.statisticsRequestWeb);
          const appRequestCounts = this.countByDate(jsonObject.statisticsRequestApp);
          this.allRequestFromToday = this.todatyCount(webRequestCounts) + this.todatyCount(appRequestCounts);
          this.allUsersEntranceFromToday = this.todatyCount(appUsersEntranceCounts) + this.todatyCount(webUsersEntranceCounts);
          this.initializeChart("myChart", webRequestCounts, appRequestCounts, webUsersEntranceCounts, appUsersEntranceCounts);

          const webUsersEntranceMonthsCounts = jsonObject.webUniqueVisitorsLast12Months;
          const appUsersEntranceMonthsCounts = jsonObject.appUniqueVisitorsLast12Months;
          const webRequestMonthsCounts = this.countByMonth(jsonObject.statisticsRequestWeb);
          const appRequestMonthsCounts = this.countByMonth(jsonObject.statisticsRequestApp);
          this.initializeChart("myChart2", webRequestMonthsCounts, appRequestMonthsCounts, webUsersEntranceMonthsCounts, appUsersEntranceMonthsCounts);

        }
      } catch (error) {
        console.log(error);
        this.showSnackBar("Error get files: " + error, "red");
      }
    },
    // Helper function to count entries by date
    countByDate(entries) {
      const counts = {};
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - 14); // 15 days including today

      // Initialize counts for each day to 0
      for (let i = 0; i < 15; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        const formattedDate = this.formatDate(date);
        counts[formattedDate] = 0;
      }

      // Filter and count entries
      entries.forEach(entry => {
        const entryDate = new Date(entry.createdAt);
        const formattedEntryDate = this.formatDate(entryDate);
        if (formattedEntryDate in counts) {
          counts[formattedEntryDate]++;
        }
      });

      return counts;
    },
    // Format date as 'DD-MM-YYYY'
    formatDate(d) {
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [day, month, year].join('-');
    },
    // Helper function to count entries by month
    countByMonth(entries) {
      const counts = {};
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth() - 11, 1); // 12 months including the current month

      // Initialize counts for each month to 0
      for (let i = 0; i < 12; i++) {
        const date = new Date(startDate);
        date.setMonth(startDate.getMonth() + i);
        const formattedDate = this.formatMonth(date);
        counts[formattedDate] = 0;
      }

      // Filter and count entries
      entries.forEach(entry => {
        const entryDate = new Date(entry.createdAt);
        const formattedEntryDate = this.formatMonth(entryDate);
        if (formattedEntryDate in counts) {
          counts[formattedEntryDate]++;
        }
      });

      return counts;
    },

    // Format date as 'MM-YYYY'
    formatMonth(d) {
      let month = '' + (d.getMonth() + 1);
      let year = d.getFullYear();

      if (month.length < 2) month = '0' + month;

      return [month, year].join('-');
    },
    todatyCount(data) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      const formattedToday = dd + '-' + mm + '-' + yyyy;
      return data[formattedToday] || 0;
    },
    initializeChart(chartId, webRequestCountsArg, appRequestCountsArg, webUsersEntranceCountsArg, appUsersEntranceCountsArg) {

      const labels = Object.keys(webRequestCountsArg); 
      console.log(labels);
      const webRequestCounts = Object.values(webRequestCountsArg);
      const appRequestCounts = Object.values(appRequestCountsArg);

      const data = {
        labels: labels,
        datasets: [{
          label: 'משתמשים אפליקציה',
          data: appUsersEntranceCountsArg,
          barThickness: 12,
          backgroundColor: 'rgba(54, 163, 235, 0.438)',
        }, {
          label: 'משתמשים אתר',
          data: webUsersEntranceCountsArg,
          barThickness: 12,
          backgroundColor: 'rgba(255, 99, 133, 0.377)',
        }, {
          label: 'פניות אפליקציה',
          data: appRequestCounts,
          barThickness: 12,
          backgroundColor: 'rgba(255, 204, 86, 0.384)',
        }, {
          label: 'פניות אתר',
          data: webRequestCounts,
          barThickness: 12,
          backgroundColor: 'rgba(153, 102, 255, 0.397)',
        }]
      };

      if (chartId === "myChart") {
        if (this.chartInstance) {
          this.chartInstance.destroy();
        }
      } else {
        if (this.chartInstance2) {
          this.chartInstance2.destroy();
        }
      }


      // Initialize the chart with these datasets
      var ctx = document.getElementById(chartId);
      this.chartInstance = new Chart(ctx, {
        type: 'bar', // Change to 'line' type chart
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1, // Confirms that ticks are placed at whole numbers
                precision: 0 // Ensures no fractional part in ticks
              }
            },
          }
        }
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  mounted() {
    this.getDetails();
  },
  // watch: {
  //   calls(newVal, oldVal) {
  //     this.initializeChart();
  //   },
  // },
}
</script>
<style scoped>
.my-custom-col {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
</style>
    