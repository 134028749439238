import ApiServices from '@/services/api.service'

class GetConstant {
    constructor() {
    }

    static async getConstant() {

        try {
            let api = process.env.VUE_APP_BASE_URL + "/web/home/get_constants";
            const res = await fetch(api, ApiServices.requestOptions("GET", "", ""));
            const jsonObject = await res.json();
            if (res.status === 400) {
                return false
            } else if (res.status === 200) {
                return jsonObject
            }
        } catch (error) {
            return false
        }

    }

    static async getSearchAutoCompleteList() {

        try {    
            let api = process.env.VUE_APP_BASE_URL + "/web/home/get_search_auto_complete";
            const res = await fetch(api, ApiServices.requestOptions("GET", "", ""));
            const jsonObject = await res.json();
            if (res.status === 400) {
                return false;
            } else if (res.status === 200) {
              const combinedArray = [];
              for (const key in jsonObject) {
                if (jsonObject.hasOwnProperty(key)) {
                  const values = jsonObject[key];
                  for (const value of values) {
                    if (value !== "") {
                      const item = { [key]: value };
                      combinedArray.push(item);
                    }
                  }
                }
              }
              return combinedArray;
            }
          } catch (error) {
            return false
          }
    }


}

export default GetConstant;


