import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './assets/styles.css'; // Import your main CSS file
import store from './store'
import VueClazyLoad from 'vue-clazy-load';
import * as VueGoogleMaps from 'vue2-google-maps'
import Ads from 'vue-google-adsense'
import i18n from './i18n.js'; // import the i18n configuration

Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

Vue.config.productionTip = false
Vue.use(VueClazyLoad);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,  // replace with your API key
    libraries: 'places',  // necessary for places input
  }
})


new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
