<template>
  <v-app >
    <v-main>
      <nav-bar v-if="$route.path !== '/login'" style="margin-bottom: 50px;"></nav-bar>

      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="isLoggedInKey" />
      </keep-alive>
      <!-- Routes without keepAlive or set to false will not use keep-alive -->
      <router-view v-if="!$route.meta.keepAlive" />

      <div style="position: absolute; bottom: 10px; right: 45%;">
        <span @click="openAccessability()" style="text-decoration: underline; cursor: pointer; margin-left: 5px;">הצהרת
          נגישות</span>
        <span @click="openTerms()" style="text-decoration: underline; cursor: pointer;">תנאי השימוש</span>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mapState } from 'vuex';
import NavBar from '@/components/widgets/navBar.vue'

export default {
  data() {
    return {
    };
  },
  components: {
        NavBar
    },
  methods: {
    openAccessability() {
      let access = document.getElementById("enable-toolbar-trigger");
      access.click();
      var he = document.querySelector('.tr-trigger');
      if (he) {
        he.click();
      }
      var statment = document.querySelector('.enable-bottomlinks-statement');
      // If the element exists, simulate a click on it
      if (statment) {
        statment.click();
      }
    },
    openTerms() {
      this.$router.push({ name: "terms" })
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
    }),
    isLoggedInKey() {
      return this.isLoggedIn ? 'loggedIn' : 'loggedOut';
    },
  },
  mounted() {


  },
}
</script>
<style>

</style>
 