<template>
    <v-app style="background-color: rgb(238, 242, 246);">
        <v-main style="margin-bottom: 70px;">
            <AddRestaurant v-if="addRest"></AddRestaurant>
            <EditRestaurant v-if="editRest"></EditRestaurant>
        </v-main>
    </v-app>
</template>
<script>
import AddRestaurant from '@/components/user/AddRestaurant'
import EditRestaurant from '@/components/user/EditRestaurant'
import Auth from '@/services/auth.service.js'

export default {
    components: {
        AddRestaurant,
        EditRestaurant,
    },
    data() {
        return {
            addRest: true,
            editRest: false,
        }
    },
    mounted() {
        let auth = Auth.checkAuth();
        if (!auth) {
            this.$router.replace({ name: "home" })
        }
        this.updatePageTitle();
        if (this.$route.params._id) {
            this.editRest = true;
            this.addRest = false;
        }
    },
    methods: {
        updatePageTitle() {
            if (this.$vuetify.rtl) {
                return document.title = 'כשר בסביבה | אזור אישי'
            } else {
                return document.title = 'Kosher Basviva | Account';
            }
        },
    }
};
</script>