<template>
  <div class="mt-5">

    <form v-if="emailVerified !== 'null'" action="" @submit.prevent="update"
      class="container form-responsive rounded border p-4" style="max-width: 500px; background-color: white;">
      <div class="text-center border fw-bold my-4 p-4 bg-light">
        <p>
        <h1 v-if="emailVerified === 'true'" style="color: rgb(6, 160, 6);">האימות בוצע בהצלחה!</h1>
        <h1 v-else-if="emailVerified === 'false'" style="color: rgb(206, 14, 14);">האימות נכשל</h1>
        <h1 v-else>החשבון שלך כבר אומת</h1>
        </p>
      </div>
    </form>

    <v-row v-else class="my-5">
      <v-col class="d-flex justify-center">
        <v-progress-circular :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snacbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>


  </div>
</template>
      
<script>
import ApiServices from '@/services/api.service'

export default {

  components: {
  },
  data() {
    return {
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      emailVerified: "null"
    }
  },
  methods: {

    async verifyEmail() {

      let token = this.$route.params.token;
      try {
        let api = process.env.VUE_APP_BASE_URL + "/auth/verify_email";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        if (res.status >= 400) {
          const jsonObject = await res.json();
          this.showSnackBar("Error verify email: " + jsonObject.message, "red");
          this.emailVerified = "false"
        } else if (res.status === 201) {
          this.emailVerified = "alreadyConfirmed"
          await this.sleep(1700);
          this.$router.replace({ name: "login"})
        }else if (res.status === 200) {
          this.showSnackBar("כתובת המייל אומתה בהצלחה!", "green");
          this.emailVerified = "true"
          await this.sleep(1700);
          this.$router.replace({ name: "login"})
        }
      } catch (error) {
        this.showSnackBar("Error verify email: " + error, "red");
      }

    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  created() {
    this.verifyEmail();
  },
}
</script>
<style></style>
      