<template>
    <div>
      <ins class="adsbygoogle"
           :style="{ display: 'block' }"
           data-ad-client="ca-pub-4354485828072211"
           data-ad-slot="9581384386"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdSense',
    
  }
  </script>
  
  <style scoped>
  .adsbygoogle {
    width: 100%;
  }
  </style>
  