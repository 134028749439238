<template>
  <v-container>
    <!-- מודעת גוגל -->
    <!-- <AdSense /> -->
    <v-row class="mt-10">

      <v-col cols="12" sm="5" style="max-width: 400px;">


        <template>
          <v-autocomplete :items="autoCompleteList" :item-value="item => item" :item-text="dynamicItemText"
            v-model="searchSelected" :label="'חפש לפי שם מסעדה, יישוב או מדינה'" outlined dense clearable
            append-icon="mdi-magnify" :menu-props="{ bottom: true, offsetY: true }" ref="autocomplete"
            @click.native.prevent="handleClick">
            <template #item="{ item }">
              <!-- <div class="d-flex"> -->
              <v-col cols="auto" sm="10">
                {{ dynamicItemText(item) }}
              </v-col>
              <v-col cols="auto" sm="2" class="hint-text">
                {{ dynamicHintText(item) }}
              </v-col>
              <!-- </div> -->
            </template>
          </v-autocomplete>
        </template>
      </v-col>

      <!-- nearby dialog -->
      <v-col cols="12" sm="2" style="max-width: 130px;">
        <v-btn @click="getLocation('clicked')" rounded>
          <v-icon dark color="#062a4e">
            mdi-map-marker-radius
          </v-icon>
          מצא בסביבה
          <v-menu v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" dark color="#062a4e" style="margin-right: 10px;" @click="openBar($event)">
                mdi-menu-down
              </v-icon>
            </template>
            <v-card style="width: 400px;">
              <v-card-text>
                <span>מרחק חיפוש: {{ selectedDistance }} ק"מ</span>
                <v-slider v-model="selectedDistance" :min="minDistance" :max="maxDistance" step="1"></v-slider>
              </v-card-text>
              <v-card-text class="no-gutters">
                <v-btn @click="applySearch">חיפוש</v-btn>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-btn>
      </v-col>

      <v-col cols="12" sm="2" style="max-width: 130px; margin-inline-start: 80px;">
        <v-btn @click="getFavoritesList()" rounded>
          <v-icon dark color="#062a4e" style="color: ;">
            mdi-heart-outline
          </v-icon>
          מועדפים
        </v-btn>
      </v-col>

    </v-row>

    <v-row v-if="progressShow">
      <v-col class="d-flex justify-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>

    <div class="divider"></div>
    <v-row class="mt-10">

      <v-col cols="6">
        <v-btn class="d-flex m-4 justify-start" @click="filterDialog = true" rounded>סינון תוצאות</v-btn>
      </v-col>

      <v-col cols="6">
        <span class="d-flex justify-start m-4 font-weight-bold"
          style="font-size: large; text-decoration: underline; color: #062a4e;">{{ totalItems + " תוצאות"
          }}</span>
      </v-col>
    </v-row>

    <v-row v-if="isLoading" class="d-flex justify-center">
      <v-col cols="12" sm="6" md="3" v-for="(item, index) in skeltonRestaurants">
        <v-skeleton-loader width="80%" height="300px" type="image, article"></v-skeleton-loader>
      </v-col>
    </v-row>

    <cards v-else :restaurants="restaurants" :userFavorites="userFavorites" :location="location" :host="host"
      @reloadFavorites="reloadFavorites" @emitShowSnackBar="emitShowSnackBar" />

    <div v-if="restaurants.length < totalItems" class="loading-indicator">
      <v-btn @click="loadMoreRestaurants">טען עוד</v-btn>
      <span v-if="loadMore" style="margin-right: 20px;">Loading....</span>
    </div>

    <FilterDialog v-model="filterDialog" v-if="filterDialog" :constants="constants" @ressetChoosed="ressetChoosed"
      :constantsChoosed="constantsChoosed" @searchFiltered="getRestaurantsFiltered"> </FilterDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import Distance from '@/util/Distance'
import FilterResults from '@/util/FilterResaults'
import Constants from '@/util/GetConstant'
import Favorites from '@/util/Favorites'
import Auth from '@/services/auth.service.js'
import FilterDialog from '@/components/home-rest-list/dialogs/FilterDialog'
// import AdSense from '@/components/widgets/AdSense.vue';
import Cards from '@/components/home-rest-list/Cards.vue';

export default {
  components: {
    SnackBar,
    FilterDialog,
    // AdSense,
    Cards
  },
  data() {
    return {
      selectZone: [],
      selectActive: [],
      progressShow: false,
      search: "",
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      restaurants: [],
      skeltonRestaurants: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], //
      itemsPerLoad: 20,
      totalItems: 0,
      restaurantsCopy: [],
      autoCompleteList: [],
      searchSelected: "",
      currentPage: 1,
      loadMore: false,
      filterd: false,
      locationClicked: false,
      location: {
        latitude: 0,
        longitude: 0,
      },
      menu: false,
      filterResults: false,
      selectedDistance: 10, // Default selected distance in km
      minDistance: 1,      // Minimum distance value
      maxDistance: 30,     // Maximum distance value
      constants: {
        categories: [],
        sub_categories: [],
        types: [],
        kashruyot: []
      },
      constantsChoosed: {
        categories: [],
        sub_categories: [],
        types: [],
        kashruyot: []
      },
      dataType: "",
      host: "",
      isLoading: true,
      initialized: false,
      userFavorites: [""],
      filterDialog: false
    }
  },
  methods: {
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    loadMoreRestaurants() {
      // Check if there are more restaurants to load
      this.loadMore = true;
      if (this.dataType === "name") {
        this.getRestaurantsByName();
      }
      if (this.dataType === "random") {
        this.getRandomRestaurants();
      }
      if (this.dataType === "location") {
        this.getRestaurantsByLocation();
      }
      // Wait until the next DOM update tick
      this.$nextTick(() => {
        this.loadMore = false; // Now set loadMore to false
      });
    },
    async getRestaurantsByName() {
      try {
        this.isLoading = true
        this.progressShow = true;
        if (!this.dataType) {
          this.restaurants = [];
        }
        // Save the current scroll position before updating restaurants
        const currentScrollTop = window.scrollY; // For page scroll

        const nameSearchJSON = JSON.stringify({
          searchSelected: this.searchSelected, filteredChoosed: this.constantsChoosed,
          startfrom: this.restaurants.length, platform: "website"
        });
        let api = process.env.VUE_APP_BASE_URL + `/web/home/get_restaurants_by_name`;

        const res = await fetch(api, ApiServices.requestOptions("POST", nameSearchJSON, ""));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.restaurants.push(...jsonObject.restaurants);
          this.totalItems = jsonObject.totalCount;
          // Reset the scroll to the previous position after adding restaurants
          this.$nextTick(() => {
            window.scrollTo(0, currentScrollTop); // For page scroll
          });
          this.restaurants = Distance.sortByDistance(this.restaurants);
          this.restaurants = this.sortByPromotions(this.restaurants);
          this.isLoading = false
          this.progressShow = false;
          this.dataType = "name";
          const key = Object.keys(this.searchSelected)[0]; // Get the single key from the object
          const value = this.searchSelected[key]; // Get the corresponding value using the key
          if (Object.keys(this.$route.query).length === 0 || (this.$route.query.key !== key || this.$route.query.value !== value)) {
            // Push the key and value to the query
            this.$router.push({ query: { ...this.$route.query, key: key, value: value } });
          }
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRandomRestaurants() {
      try {
        this.isLoading = true
        this.restaurants = [];
        this.progressShow = true;
        const JsonBody = JSON.stringify({ filteredChoosed: this.constantsChoosed, platform: "website" });
        let api = process.env.VUE_APP_BASE_URL + `/web/home/get_restaurants_list?platform=website`;
        const res = await fetch(api, ApiServices.requestOptions("POST", JsonBody, ""));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.progressShow = false;
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants = jsonObject;
          this.totalItems = jsonObject.length;
          this.isLoading = false
          this.progressShow = false;
          this.dataType = "random";
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRestaurantsByLocation() {
      try {
        this.isLoading = true
        this.progressShow = true;
        if (!this.dataType) {
          this.restaurants = [];
        }
        // Save the current scroll position before updating restaurants
        const currentScrollTop = window.scrollY; // For page scroll
        const locationJSON = JSON.stringify({
          location: this.location, distance: this.selectedDistance, filteredChoosed: this.constantsChoosed,
          startfrom: this.restaurants.length, platform: "website"
        });

        let api = process.env.VUE_APP_BASE_URL + "/web/home/get_restaurants_list_by_location";
        const res = await fetch(api, ApiServices.requestOptions("POST", locationJSON, ""));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.progressShow = false;
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants.push(...jsonObject.restaurants);
          this.totalItems = jsonObject.totalCount;
          // Reset the scroll to the previous position after adding restaurants
          this.$nextTick(() => {
            window.scrollTo(0, currentScrollTop); // For page scroll
          });
          this.dataType = "location";
          this.isLoading = false
          this.restaurants = this.sortByPromotions(this.restaurants);
          this.progressShow = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRestaurantsFiltered() {

      if (this.dataType === "name") {
        this.dataType = "";
        this.getRestaurantsByName(true);
      }
      if (this.dataType === "random") {
        this.dataType = "";
        this.getRandomRestaurants();
      }
      if (this.dataType === "location") {
        this.dataType = "";
        this.getRestaurantsByLocation(true);
      }
      if (this.dataType === "favorites") {
        this.dataType = "";
        this.getUserFavorites();
      }
      // add filters to params
      if (this.dataType !== "favorites") {
        try {
          let filters = FilterResults.filterResults(this.restaurants, this.constantsChoosed);
          const filtersParams = filters.filtersParams;
          // Merge current query with new filtersParams
          const newQuery = { ...this.$route.query, ...filtersParams };
          // Check if the current query is different from the new query
          if (JSON.stringify(this.$route.query) !== JSON.stringify(newQuery)) {
            // Push only if the new query is different
            this.$router.push({ query: newQuery });
          }
        } catch (error) {
        }
      }
    },
    ressetChoosed() {
      this.constantsChoosed = {
        categories: [],
        sub_categories: [],
        types: [],
        kashruyot: []
      }
      this.getRestaurantsFiltered();
    },
    async getFavoritesList() {
      try {
        this.isLoading = true
        this.restaurants = [];
        this.restaurantsCopy = [];
        this.progressShow = true;
        const favoritesJSON = JSON.stringify({ favoritesList: this.userFavorites });
        let api = process.env.VUE_APP_BASE_URL + `/web/home/get_favorites_list`;
        const res = await fetch(api, ApiServices.requestOptions("POST", favoritesJSON, ""));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants = jsonObject;
          this.isLoading = false
          this.dataType = "favorites";
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get favorites list: " + error, "red");
      }

    },
    setFiltersFromParams() {
      this.constantsChoosed.categories = this.$route.query.fs_categories
        ? this.$route.query.fs_categories.split(',')
        : [];
      this.constantsChoosed.sub_categories = this.$route.query.fs_categories
        ? this.$route.query.fs_sub_categories.split(',')
        : [];
      console.log("kashrut before setting filter", this.constantsChoosed.kashruyot);
      this.constantsChoosed.kashruyot = this.$route.query.fs_kashrut
        ? this.$route.query.fs_kashrut.split(',')
        : [];
      console.log("kashrut after setting filter", this.constantsChoosed.kashruyot);

      this.constantsChoosed.types = this.$route.query.fs_types
        ? this.$route.query.fs_types.split(',')
        : [];
      this.getRestaurantsFiltered();
    },
    handleClick() {
      this.$refs.autocomplete.isMenuActive = false; // This opens the menu
    },
    getLocation(clicked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.showPosition(position);
            { true }
            this.getRestaurantsByLocation();
          },
          error => {
            if (clicked) {
              //  console.log(error);
              return this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
            }
            this.getRandomRestaurants();
          }
        );
      } else {
        this.showSnackBar("Geolocation is not supported", "red");
      }
    },
    showPosition(position) {
      this.location.latitude = position.coords.latitude;
      this.location.longitude = position.coords.longitude;
    },
    dynamicItemText(item) {
      // Assuming item has at least one key
      const keys = Object.keys(item);
      const firstKey = keys[0];
      return item[firstKey];
    },
    dynamicHintText(item) {
      // Assuming item has at least one key
      const keys = Object.keys(item);
      const firstKey = keys[0];
      return firstKey;
    },
    openBar(event) {
      // Prevent the click event from propagating to the v-btn
      event.stopPropagation();
      this.menu = !this.menu
      // Implement your menu down icon click action here
    },
    openFilterResults() {
      this.filterResults = !this.filterResults
    },
    applySearch() {
      this.menu = false; // Close the menu after applying the search
      this.getRestaurantsByLocation();
    },

    async getConstants() {
      if (this.$store.state.constant?.categories?.[0]) {
        this.constants = this.$store.state.constant
        return
      }
      const constants = await Constants.getConstant();
      if (!constants) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.constants = constants
      this.$store.state.constant = constants;
    },
    async getSearchAutoCompleteList() {
      const autoCompletes = await Constants.getSearchAutoCompleteList();
      if (!autoCompletes) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.autoCompleteList = autoCompletes;
    },
    async getUserFavorites() {
      let auth = Auth.checkAuth();
      if (!auth) {
        return this.userFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
      }
      this.userFavorites = await Favorites.getUserFavorites();
    },

    sortByPromotions(restaurants) {
      const currentDate = new Date();
      return restaurants.sort((a, b) => {
        const aPromotion = a.promotion.isPromotion && new Date(a.promotion.dateEnd) > currentDate;
        const bPromotion = b.promotion.isPromotion && new Date(b.promotion.dateEnd) > currentDate;
        return bPromotion - aPromotion;
      });
    },

    loadAdSenseScript() {
      return new Promise((resolve, reject) => {
        if (document.querySelector('script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    reloadFavorites() {
      this.getUserFavorites();
    },
    emitShowSnackBar(message, color) {
      this.showSnackBar(message, color);
    },
  },

  watch: {
    searchSelected() {
      this.dataType = "";
      if (this.searchSelected) {
        this.getRestaurantsByName(true);
      } else {
        // clean the query
        this.$router.push({ query: {} });
        this.getLocation(null);
      }
    }
  },
  mounted() {
    // this.loadAdSenseScript().then(() => {
    //   (adsbygoogle = window.adsbygoogle || []).push({});
    // }).catch((error) => {
    //   console.error('Failed to load AdSense script:', error);
    // });

    this.host = process.env.VUE_APP_BASE_URL;

    this.setFiltersFromParams();

    if (!this.$route.query.key) {
      this.getLocation(null);
    } else {
      // set query to search
      this.searchSelected = {
        [this.$route.query.key]: this.$route.query.value
      };
    }

    this.getSearchAutoCompleteList();
    this.getConstants();
    this.getUserFavorites();


  }
}
</script>
<style scoped>
/* Loading indicator styles */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  /* Set an appropriate height */
  font-weight: bold;
  color: #777;
}

.no-gutters {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 16px !important;
  padding-top: 0px !important;
  display: flex;
  justify-content: center;
}


.hint-text {
  font-size: 12px;
  color: #888;
}


::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb {
  background: #062a4e !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: black !important;
}

.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */

}
</style>
    

