<template>
  <v-app style="background-color: rgb(238, 242, 246);">
    <v-main style="margin-bottom: 70px;">
      <RestaurantsList v-if="$vuetify.breakpoint.lgAndUp" />
      <RestaurantsListSmall v-if="$vuetify.breakpoint.lgAndDown" />
    </v-main>
  </v-app>
</template>
<script>
import RestaurantsList from '@/components/home-rest-list/RestaurantsList'
import RestaurantsListSmall from '@/components/home-rest-list/RestaurantsListSmall'

export default {
  name:"homePage",
  components: {
    RestaurantsList,
    RestaurantsListSmall,
  },
  mounted() {
  this.updatePageTitle();
},
methods: {
  updatePageTitle() {
    if (this.$vuetify.rtl) {
     return document.title = 'כשר בסביבה | מסעדות כשרות למהדרין'; // Hebrew title
    } else {
     return document.title = 'Kosher Basviva | Mehadrin Kosher Restaurants'; // English title
    }
  },
}
}
</script>
 