<template >
  <v-container >

    <v-row>
      <v-col cols="2">
        <div class="card1 scrollable-content scroll-container" :class="containerClass">
          <div class="centered-div">
            <span :style="setMarginRigth(10)"
              style="font-weight: 800; font-size: x-large; color: #000;">{{ $t('home.search_title') }}</span>
          </div>
          <div class="divider"></div>
          <div style="padding-bottom: 5px; padding-top: 20px; padding-left: 5px; padding-right: 5px;">
            <v-autocomplete :items="autoCompleteList" :item-value="item => item" :item-text="dynamicItemText"
              v-model="searchSelected" :label="$t('home.search_label')" clearable append-icon="mdi-magnify"
              :menu-props="{ bottom: true, offsetY: true }" ref="autocomplete" @click.native.prevent="handleClick">
              <template #item="{ item }">
                <v-col cols="auto" sm="10">
                  {{ dynamicItemText(item) }}
                </v-col>
                <v-col cols="auto" sm="2" class="hint-text">
                  {{ dynamicHintText(item) }}
                </v-col>
              </template>
            </v-autocomplete>
          </div>

          <div class="d-flex" style="padding-left: 5px; padding-right: 5px;">
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card @click="getLocation('clicked')" :elevation="hover ? 16 : 2"
                style="width: 50%; height: 100px; margin-left: 5px; margin-right: 5px;">
                <div class="d-flex justify-end" style="padding: 5px;">
                  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small dark color="#062a4e" :style="setMarginRigth(10)"
                            @click="openBar($event)">
                            mdi-cog
                          </v-icon>
                        </template>
                        <span>{{ $t('home.change_distance') }}</span>
                      </v-tooltip>
                    </template>
                    <v-card style="width: 400px;">
                      <v-card-text>
                        <span>{{ $t('home.distance_search') }}: {{ selectedDistance }} {{ $t('home.kilometer') }}</span>
                        <v-slider v-model="selectedDistance" :min="minDistance" :max="maxDistance" step="1"></v-slider>
                      </v-card-text>
                      <v-card-text class="no-gutters">
                        <v-btn @click="applySearch">{{ $t('home.update_distance') }}</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div class="d-flex justify-center" style="margin-bottom: 5px; margin-top: 5px;">
                  {{ $t('home.my_location') }}
                </div>
                <div class="d-flex justify-center">
                  <v-icon dark color="#062a4e">
                    mdi-map-marker-radius
                  </v-icon>
                </div>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card @click="getFavoritesList()" :elevation="hover ? 16 : 2" class="d-flex align-center justify-center"
                style="width: 50%; height: 100px; margin-left: 5px; margin-right: 5px; ">
                <div>
                  <div style="margin-bottom: 5px; margin-top: 5px;">
                    {{ $t('home.my_favorites') }}
                  </div>
                  <div class="d-flex justify-center">
                    <v-icon dark color="#062a4e">
                      mdi-heart-outline
                    </v-icon>
                  </div>
                </div>
              </v-card>
            </v-hover>
          </div>
          <div class="divider"></div>

          <div class="centered-div">
            <span :style="setMarginRigth(10)" style="font-weight: 800; font-size: x-large; color: #000;">
              {{ $t('home.filter_title') }}</span>
          </div>
          <div class="divider"></div>

          <div style="margin-bottom: 70px; " class="filter-panel">
            <v-expansion-panels accordion focusable flat style="max-width: 100%; ">
              <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                <v-expansion-panel-header>{{ $t('home.filter_kashrut_title') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="margin-bottom: 10px;"
                    v-if="!constantsChoosed.kashruyot.length > 0 || constantsChoosed.kashruyot.every(item => item === false || item === 'empty')">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                  </div>
                  <v-checkbox v-for="(kashrut, index) in constants.kashruyot" v-model="constantsChoosed.kashruyot[index]"
                    :label="kashrut" :value="kashrut" style="margin-bottom: 0px !important; margin-top: 0px !important;">
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                <v-expansion-panel-header>{{ $t('home.filter_type_title') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="margin-bottom: 10px;"
                    v-if="!constantsChoosed.types.length > 0 || constantsChoosed.types.every(item => item === false || item === 'empty')">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                  </div>
                  <v-checkbox v-for="(type, index) in constants.types" v-model="constantsChoosed.types[index]"
                    :value="type" :label="type" style="margin-bottom: 0px !important; margin-top: 0px !important;">
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                <v-expansion-panel-header>{{ $t('home.filter_category_title') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="margin-bottom: 10px;"
                    v-if="!constantsChoosed.categories.length > 0 || constantsChoosed.categories.every(item => item === false || item === 'empty')">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                  </div>
                  <v-checkbox v-for="(category, index) in constants.categories"
                    v-model="constantsChoosed.categories[index]" :value="category" :label="category"
                    style="margin-bottom: 0px !important; margin-top: 0px !important;">
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                <v-expansion-panel-header>{{ $t('home.filter_sub_category_title') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="margin-bottom: 10px;"
                    v-if="!constantsChoosed.sub_categories.length > 0 || constantsChoosed.sub_categories.every(item => item === false || item === 'empty')">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                  </div>
                  <v-checkbox v-for="(sub_category, index) in constants.sub_categories"
                    v-model="constantsChoosed.sub_categories[index]" :value="sub_category" :label="sub_category"
                    style="margin-bottom: 0px !important; margin-top: 0px !important;">
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </div>
          <div style="margin-top: 50px; font-weight: 600;">
            <span :style="setMarginRigth(10)"
              style="font-size: medium; background-color: #f1a9a073; border-radius: 10px; padding: 7px;">
              {{ $t('home.missing_restaurant') }}
              <span @click="addRest()" style="color: rgb(9, 9, 243); cursor: pointer;">{{ $t('home.add_now') }}</span>
            </span>
          </div>
        </div>
      </v-col>

      <v-col cols="10">

        <v-row v-if="progressShow" style="margin-top: 80px;">
          <v-col class="d-flex justify-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-row>

        <!-- מודעת גוגל -->
        <!-- <AdSense /> -->

        <v-row class="d-flex justify-center mt-5">

          <v-col cols="12">
            <span class="d-flex justify-center m-4 font-weight-bold"
              style="font-size: x-large; text-decoration: underline; color: #062a4e;">{{ totalItems + " " +
                $t('home.results')
              }}</span>
          </v-col>
        </v-row>

        <v-row v-if="isLoading" class="d-flex justify-center">
          <v-col cols="auto" sm="6" md="4" v-for="(item, index) in skeltonRestaurants">
            <v-skeleton-loader width="80%" height="300px" type="image, article"></v-skeleton-loader>
          </v-col>

        </v-row>

        <cards v-else :restaurants="restaurants" :userFavorites="userFavorites" :location="location" :host="host"
          @reloadFavorites="reloadFavorites" @emitShowSnackBar="emitShowSnackBar" />

        <div v-if="restaurants.length < totalItems" class="loading-indicator">
          <v-btn @click="loadMoreRestaurants">{{ $t('home.load_more') }}</v-btn>
          <span v-if="loadMore" :style="setMarginRigth(20)">{{ $t('home.loading') }}</span>
        </div>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import Distance from '@/util/Distance'
import FilterResults from '@/util/FilterResaults'
import Constants from '@/util/GetConstant'
import Favorites from '@/util/Favorites'
import Auth from '@/services/auth.service.js'
// import AdSense from '@/components/widgets/AdSense.vue';
import Cards from '@/components/home-rest-list/Cards.vue';

export default {
  components: {
    SnackBar,
    // AdSense,
    Cards
  },
  data() {
    return {
      selectZone: [],
      selectActive: [],
      progressShow: false,
      search: "",
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      restaurants: [],
      skeltonRestaurants: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      itemsPerLoad: 20,
      totalItems: 0,
      autoCompleteList: [],
      searchSelected: "",
      currentPage: 1,
      loadMore: false,
      filterd: false,
      locationClicked: false,
      location: {
        latitude: 0,
        longitude: 0,
      },
      menu: false,
      filterResults: false,
      selectedDistance: 10, // Default selected distance in km
      minDistance: 1,      // Minimum distance value
      maxDistance: 30,     // Maximum distance value
      constants: {
        categories: [],
        sub_categories: [],
        types: [],
        kashruyot: []
      },
      constantsChoosed: {
        categories: [],
        sub_categories: [],
        types: [],
        kashruyot: []
      },
      dataType: "",
      host: "",
      isLoading: true,
      initialized: false,
      userFavorites: [""],
    }
  },
  methods: {
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    loadMoreRestaurants() {
      // Check if there are more restaurants to load
      this.loadMore = true;
      if (this.dataType === "name") {
        this.getRestaurantsByName();
      }
      if (this.dataType === "random") {
        this.getRandomRestaurants();
      }
      if (this.dataType === "location") {
        this.getRestaurantsByLocation();
      }
      // Wait until the next DOM update tick
      this.$nextTick(() => {
        this.loadMore = false; // Now set loadMore to false
      });
    },
    async getRestaurantsByName() {
      try {
        this.isLoading = true
        this.progressShow = true;
        if (!this.dataType) {
          this.restaurants = [];
        }
        const nameSearchJSON = JSON.stringify({
          searchSelected: this.searchSelected, filteredChoosed: this.constantsChoosed,
          startfrom: this.restaurants.length, platform: "website"
        });
        let api = process.env.VUE_APP_BASE_URL + `/web/home/get_restaurants_by_name`;

        const res = await fetch(api, ApiServices.requestOptions("POST", nameSearchJSON, ""));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
          this.progressShow = false;
        } else if (res.status === 200) {
          this.restaurants.push(...jsonObject.restaurants);
          this.totalItems = jsonObject.totalCount;
          this.restaurants = Distance.sortByDistance(this.restaurants);
          this.restaurants = this.sortByPromotions(this.restaurants);
          this.isLoading = false
          this.progressShow = false;
          this.dataType = "name";
          const key = Object.keys(this.searchSelected)[0]; // Get the single key from the object
          const value = this.searchSelected[key]; // Get the corresponding value using the key
          if (Object.keys(this.$route.query).length === 0 || (this.$route.query.key !== key || this.$route.query.value !== value)) {
            // Push the key and value to the query
            this.$router.push({ query: { ...this.$route.query, key: key, value: value } });
          }
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRandomRestaurants() {
      try {
        this.isLoading = true
        this.restaurants = [];
        this.progressShow = true;
        const JsonBody = JSON.stringify({ filteredChoosed: this.constantsChoosed, platform: "website" });
        let api = process.env.VUE_APP_BASE_URL + `/web/home/get_restaurants_list?platform=website`;
        const res = await fetch(api, ApiServices.requestOptions("POST", JsonBody, ""));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.progressShow = false;
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants = jsonObject;
          this.totalItems = jsonObject.length;
          this.isLoading = false
          this.progressShow = false;
          this.dataType = "random";
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRestaurantsByLocation() {
      try {
        this.isLoading = true
        this.progressShow = true;
        if (!this.dataType) {
          this.restaurants = [];
        }
        const locationJSON = JSON.stringify({
          location: this.location, distance: this.selectedDistance, filteredChoosed: this.constantsChoosed,
          startfrom: this.restaurants.length, platform: "website"
        });

        let api = process.env.VUE_APP_BASE_URL + "/web/home/get_restaurants_list_by_location";
        const res = await fetch(api, ApiServices.requestOptions("POST", locationJSON, ""));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.progressShow = false;
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants.push(...jsonObject.restaurants);
          this.totalItems = jsonObject.totalCount;
          this.dataType = "location";
          this.isLoading = false
          this.restaurants = this.sortByPromotions(this.restaurants);
          this.progressShow = false;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get restaurants list: " + error, "red");
      }
    },
    async getRestaurantsFiltered() {
      if (this.dataType === "name") {
        this.dataType = "";
        this.getRestaurantsByName();
      }
      if (this.dataType === "random") {
        this.dataType = "";
        this.getRandomRestaurants();
      }
      if (this.dataType === "location") {
        this.dataType = "";
        this.getRestaurantsByLocation();
      }
      if (this.dataType === "favorites") {
        this.dataType = "";
        this.getUserFavorites();
      }
      // add filters to params
      if (this.dataType !== "favorites") {
        try {
          let filters = FilterResults.filterResults(this.restaurants, this.constantsChoosed);
          const filtersParams = filters.filtersParams;
          // Merge current query with new filtersParams
          const newQuery = { ...this.$route.query, ...filtersParams };
          // Check if the current query is different from the new query
          if (JSON.stringify(this.$route.query) !== JSON.stringify(newQuery)) {
            // Push only if the new query is different
            this.$router.push({ query: newQuery });
          }
        } catch (error) {
        }
      }
    },
    async getFavoritesList() {
      try {
        this.isLoading = true
        this.restaurants = [];
        this.progressShow = true;
        const favoritesJSON = JSON.stringify({ favoritesList: this.userFavorites });
        let api = process.env.VUE_APP_BASE_URL + `/web/home/get_favorites_list`;
        const res = await fetch(api, ApiServices.requestOptions("POST", favoritesJSON, ""));
        this.progressShow = false;
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.restaurants = jsonObject;
          this.totalItems = jsonObject.length;
          this.dataType = "favorites";
          this.isLoading = false
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get favorites list: " + error, "red");
      }

    },
    setFiltersFromParams() {
      this.constantsChoosed.categories = this.$route.query.fs_categories
        ? this.$route.query.fs_categories.split(',')
        : [];
      this.constantsChoosed.sub_categories = this.$route.query.fs_categories
        ? this.$route.query.fs_sub_categories.split(',')
        : [];
      this.constantsChoosed.kashruyot = this.$route.query.fs_kashrut
        ? this.$route.query.fs_kashrut.split(',')
        : [];

      this.constantsChoosed.types = this.$route.query.fs_types
        ? this.$route.query.fs_types.split(',')
        : [];
    },
    handleClick() {
      this.$refs.autocomplete.isMenuActive = false; // This opens the menu
    },
    getLocation(clicked) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.showPosition(position);
            { true }
            this.getRestaurantsByLocation();
          },
          error => {
            if (clicked) {
              //  console.log(error);
              return this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
            }
            this.getRandomRestaurants();
          }
        );
      } else {
        this.showSnackBar("Geolocation is not supported", "red");
      }
    },
    showPosition(position) {
      this.location.latitude = position.coords.latitude;
      this.location.longitude = position.coords.longitude;
    },
    dynamicItemText(item) {
      // Assuming item has at least one key
      const keys = Object.keys(item);
      const firstKey = keys[0];
      return item[firstKey];
      // return `${item[firstKey]} (${heb(item[firstKey])})`;
    },

    dynamicHintText(item) {
      // Assuming item has at least one key
      const keys = Object.keys(item);
      const firstKey = keys[0];
      return firstKey;
    },
    openBar(event) {
      // Prevent the click event from propagating to the v-btn
      event.stopPropagation();
      this.menu = !this.menu
      // Implement your menu down icon click action here
    },
    openFilterResults() {
      this.filterResults = !this.filterResults
    },
    applySearch() {
      this.menu = false; // Close the menu after applying the search
      this.getRestaurantsByLocation();
    },

    async getConstants() {
      if (this.$store.state.constant?.categories?.[0]) {
        this.constants = this.$store.state.constant
        return
      }
      const constants = await Constants.getConstant();
      if (!constants) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.constants = constants
      this.$store.state.constant = constants;
    },
    async getSearchAutoCompleteList() {
      const autoCompletes = await Constants.getSearchAutoCompleteList();
      if (!autoCompletes) {
        this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
      }
      this.autoCompleteList = autoCompletes;
    },

    async getUserFavorites() {
      let auth = Auth.checkAuth();
      if (!auth) {
        return this.userFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
      }
      this.userFavorites = await Favorites.getUserFavorites();
    },

    addRest() {
      let auth = Auth.checkAuth();
      if (auth) {
        this.$router.push({ name: "userAccount" })
      } else {
        this.$router.push({ name: "login" })
      }
    },
    sortByPromotions(restaurants) {
      const currentDate = new Date();
      return restaurants.sort((a, b) => {
        const aPromotion = a.promotion.isPromotion && new Date(a.promotion.dateEnd) > currentDate;
        const bPromotion = b.promotion.isPromotion && new Date(b.promotion.dateEnd) > currentDate;
        return bPromotion - aPromotion;
      });
    },
    loadAdSenseScript() {
      return new Promise((resolve, reject) => {
        if (document.querySelector('script[src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    reloadFavorites() {
      this.getUserFavorites();
    },
    emitShowSnackBar(message, color) {
      this.showSnackBar(message, color);
    },
    setMarginRigth(pixels) {
      return this.$vuetify.rtl ? `margin-right: ${pixels}px` : `margin-left: ${pixels}px`;
    },
    setMarginLeft(pixels) {
      return this.$vuetify.rtl ? `margin-left: ${pixels}px` : `margin-right: ${pixels}px`;
    },
    // handleScroll() {
    //   // Calculate the scroll position for the entire page
    //   const scrollPosition = window.innerHeight + window.scrollY;
    //   const pageHeight = document.documentElement.offsetHeight;
    //   console.log(333333);

    //   if (scrollPosition >= pageHeight) {
    //     console.log(444444444);
    //     this.loadMoreRestaurants(); // Action when bottom is reached
    //   }
    // },
  },
  computed: {
    containerClass() {
      return this.$vuetify.rtl ? 'container-rtl' : 'container-ltr';
    }
  },
  watch: {
    searchSelected() {
      this.dataType = "";
      if (this.searchSelected) {
        this.getRestaurantsByName();
      } else {
        // clean the query
        this.$router.push({ query: {} });
        this.getLocation(null);
      }
    },
    constantsChoosed: {
      handler(newValue, oldValue) {
        this.getRestaurantsFiltered();
      },
      deep: true
    }
  },
  mounted() {
    // this.loadAdSenseScript().then(() => {
    //   (adsbygoogle = window.adsbygoogle || []).push({});
    // }).catch((error) => {
    //   console.error('Failed to load AdSense script:', error);
    // });

    this.host = process.env.VUE_APP_BASE_URL;

    this.setFiltersFromParams();

    if (!this.$route.query.key) {
      this.getLocation(null);
    } else {
      // set query to search
      this.searchSelected = {
        [this.$route.query.key]: this.$route.query.value
      };
    }
    this.getSearchAutoCompleteList();
    this.getConstants();
    this.getUserFavorites();

    // window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>
<style >
/* Loading indicator styles */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  /* Set an appropriate height */
  font-weight: bold;
  color: #777;
}

.no-gutters {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 16px !important;
  padding-top: 0px !important;
  display: flex;
  justify-content: center;
}


.hint-text {
  font-size: 12px;
  color: #888;
}


::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: #062a4e !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: black !important;
}



.container-rtl {
  position: fixed !important;
  top: 91px !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 300px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100%;
  background-color: rgb(255, 255, 255) !important;
  border-left: 0.5px solid #cfcccc;
}

.container-ltr {
  position: fixed !important;
  top: 91px !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 300px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100%;
  background-color: rgb(255, 255, 255) !important;
  border-right: 0.5px solid #cfcccc;
}

.scrollable-content {
  overflow-y: auto;
  max-height: 100vh;
  /* adjust as needed */
}

.scroll-container {
  /* Enable vertical scrolling */
  scrollbar-width: none;
  /* Hide scrollbar on Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar on IE/Edge */
}

/* For Webkit browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.centered-div {
  padding: 20px 0;

}

.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */

}</style>

