
class Distance {
  constructor() {
  }

  static sortByDistance(list) {
    // Sort the list by the distance property in ascending order
    list.sort((a, b) => a.distance - b.distance);
    return list;
  }

  static checkDistance(myLocation, itemLocation) {

    let dist = haversineDistance(myLocation.latitude, myLocation.longitude, itemLocation.latitude, itemLocation.longitude);

    // Round the distance to one decimal place
    dist = dist.toFixed(1);

    return dist;

  }


}

export default Distance;


function haversineDistance(lat1, lon1, lat2, lon2) {

  const R = 6371000; // Earth's radius in meters
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}