import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RestDetailsView from '../views/RestDetailsView.vue'
import LoginView from '../views/auth/LoginView.vue'
import UserView from '../views/UserView.vue'
import AdminView from '../views/admin/AdminView.vue'
import RessetPassView from '../views/auth/ResetPasswordView.vue'
import VerifyEmailView from '../views/auth/VerifyEmailView.vue'
import StatisticsView from '../views/admin/StatisticsView.vue'
import LogsView from '../views/admin/LogsView.vue'
// import { loadLanguageAsync } from '../services/translator'; // Import the language loader

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/list'
  },
  {
    path: '/list',
    name: 'home',
    component: HomeView,
    meta: { keepAlive: true } // Ensure this is active
  },
  {
    path: '/restaurant-details/:id',
    name: 'restaurant-details',
    component: RestDetailsView,
    meta: { keepAlive: false } // Ensure this is correctly set
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { keepAlive: false }, // Custom flag to indicate this route should be kept alive
    // beforeEnter: (to, from, next) => {
    //   loadLanguageAsync('he', 'home').then(() => next());
    // }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsView.vue'),
    meta: { keepAlive: false } // Custom flag to indicate this route should be kept alive
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { keepAlive: false } // Custom flag to indicate this route should be kept alive
  },
  {
    path: '/resset-password/:token',
    name: 'ressetPass',
    component: RessetPassView,
    meta: { keepAlive: false } // Custom flag to indicate this route should be kept alive
  },
  {
    path: '/verify-email/:token',
    name: 'verifyEmail',
    component: VerifyEmailView,
    meta: { keepAlive: false } // Custom flag to indicate this route should be kept alive
  },
  {
    path: '/user-account',
    name: 'userAccount',
    component: UserView,
    meta: { keepAlive: false } // Custom flag to indicate this route should be kept alive
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView,
  },
  {
    path: '/admin-statistics',
    name: 'statistics',
    component: StatisticsView,
  },
  {
    path: '/admin-account',
    name: 'adminAccount',
    component: AdminView,
    meta: { keepAlive: false } // Custom flag to indicate this route should be kept alive

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router
